import { ADMIN_AUTH } from '@/store/modules/user';

/**
 * @description Список маршрутов для администратора.
 * @type {any[]}
 */
const adminRoutes = [
  {
    component: () => import('@/views/AdminPanel/Index.vue'),
    name: 'AdminPanelIndex',
    path: '/',
  },
  {
    component: () => import('@/views/AdminPanel/PatientListImport.vue'),
    name: 'AdminPanelPatientListImport',
    path: '/import-patients',
  },
  {
    component: () => import('@/views/AdminPanel/PriceListImport.vue'),
    name: 'AdminPanelPriceListImport',
    path: '/import-prices',
  },
  {
    component: () => import('@/views/AdminPanel/ClinicDocumentTemplateList.vue'),
    name: 'AdminPanelDocumentTemplateList',
    path: '/clinics/templates',
  },
  {
    component: () => import('@/views/AdminPanel/EgiszIemkClinics.vue'),
    name: 'AdminPanelEgiszIemkClinics',
    path: '/egisz-iemk-clinics',
  },
  {
    component: () => import('@/views/AdminPanel/NotificationCreation.vue'),
    name: 'AdminPanelNotificationCreation',
    path: '/notifications/create',
  },
  {
    component: () => import('@/views/AdminPanel/NotificationList.vue'),
    name: 'AdminPanelNotificationList',
    path: '/notifications',
  },
  {
    component: () => import('@/views/AdminPanel/OnlineCheckAccountList.vue'),
    name: 'AdminPanelOnlineCheckAccountList',
    path: '/online-check/accounts',
  },
  {
    component: () => import('@/views/AdminPanel/SmsClinicPlans.vue'),
    name: 'AdminPanelSmsClinicPlans',
    path: '/sms-clinics/:clinicId/plans',
    props: (route) => ({
      clinicId: Number(route.params.clinicId),
      clinicName: route.params.clinicName,
    }),
  },
  {
    component: () => import('@/views/AdminPanel/SmsClinics.vue'),
    name: 'AdminPanelSmsClinics',
    path: '/sms-clinics',
  },
  {
    component: () => import('@/views/AdminPanel/SmsTemplates.vue'),
    name: 'AdminPanelSmsTemplates',
    path: '/sms-templates',
  },
  {
    component: () => import('@/views/AdminPanel/SmsTemplateCreation.vue'),
    name: 'AdminPanelSmsTemplateCreation',
    path: '/sms-template-creation',
  },
  {
    component: () => import('@/views/AdminPanel/MangoOfficeClinics.vue'),
    name: 'AdminPanelMangoOfficeClinics',
    path: '/mango-office-clinics',
  },
  {
    component: () => import('@/views/AdminPanel/UisClinics.vue'),
    name: 'AdminPanelUisClinics',
    path: '/uis-clinics',
  },
  {
    component: () => import('@/views/AdminPanel/TreatmentPlanTemplating.vue'),
    name: 'AdminPanelTreatmentPlanTemplating',
    path: '/treatment-plan-templating',
  },
  {
    component: () => import('@/views/AdminPanel/UserList.vue'),
    name: 'AdminPanelUserList',
    path: '/users',
  },
  {
    component: () => import('@/views/AdminPanel/VisitDiaryTemplating.vue'),
    name: 'AdminPanelVisitDiaryTemplating',
    path: '/visit-diary-templating',
  },
  {
    component: () => import('@/views/AdminPanel/PaidClinics.vue'),
    name: 'AdminPanelPaidClinics',
    path: '/paid-clinics',
  },
  {
    component: () => import('@/views/AdminPanel/IntegratedClinics.vue'),
    name: 'AdminPanelIntegratedClinics',
    path: '/integrated-clinics',
  },
  {
    component: () => import('@/views/AdminPanel/SuggestedIdeaList.vue'),
    name: 'AdminPanelSuggestedIdeaList',
    path: '/suggested-ideas',
  },
  {
    component: () => import('@/views/AdminPanel/ConsultationOrderList.vue'),
    name: 'AdminPanelConsultationOrderList',
    path: '/consultation-orders',
  },
  {
    component: () => import('@/views/AdminPanel/GreenApiAccountList.vue'),
    name: 'AdminPanelGreenApiAccountList',
    path: '/green-api/accounts',
  },
  {
    component: () => import('@/views/AdminPanel/MedflexAccountList.vue'),
    name: 'AdminPanelMedflexAccountList',
    path: '/medflex/accounts',
  },
  {
    component: () => import('@/views/AdminPanel/MedflexOrganizationList.vue'),
    name: 'AdminPanelMedflexOrganizationList',
    path: '/medflex/organizations',
  },
  {
    component: () => import('@/views/AdminPanel/DemoClinics.vue'),
    name: 'AdminPanelDemoClinics',
    path: '/demo-clinics',
  },
  {
    component: () => import('@/views/AdminPanel/MedflexHistoryRegisterDocuments.vue'),
    name: 'AdminPanelMedflexHistoryRegisterDocuments',
    path: '/medflex/accounts/:clinicId/documents',
    props: (route) => ({
      clinicId: Number(route.params.clinicId),
      clinicName: route.params.clinicName,
    }),
  },
  {
    component: () => import('@/views/AdminPanel/MessageHistory.vue'),
    name: 'AdminPanelMessageHistory',
    path: '/message-history',
  },
  {
    component: () => import('@/views/AdminPanel/UpdatesList.vue'),
    name: 'AdminPanelUpdatesList',
    path: '/updates-list',
  },
].map((route) => Object.assign(route, {
  meta: {
    authOf: ADMIN_AUTH,
    layout: 'admin-panel-layout',
  },
}));

/**
 * @description Список общих маршрутов.
 * @var {any[]}
 */
const generalRoutes = [
  {
    component: () => import('@/views/Auth.vue'),
    name: 'Auth',
    meta: {
      layout: 'auth-layout',
    },
    path: '/login',
    props: true,
  },
];

export default [
  ...adminRoutes,
  ...generalRoutes,
];
