import { Either } from 'monet';
import LeftResponse from '@/storage/LeftResponse';

/**
 * @description Архивный сотрудник.
 *
 * @param id {Number}
 * @param name {String}
 * @param clinic {ArchiveEmployeeClinic}
 * @constructor
 */
export function ArchiveEmployee(id, name, clinic) {
  this.id = id;
  this.name = name;
  this.clinic = clinic;
}

/**
 * @description Клиника архивного сотрудника
 *
 * @param id {Number}
 * @param name {String}
 * @constructor
 */
export function ArchiveEmployeeClinic(id, name) {
  this.id = id;
  this.name = name;
}

/**
 * @description Шлюз сотрудников.
 * @param {Axios} axios HTTP-клиент.
 * @constructor
 */
export default function EmployeeGateway(axios) {
  /**
   * @description Получить список архивных сотрудников.
   * @returns {Promise<Either>}
   */
  this.viewArchiveEmployeesList = () => axios
    .get('/api/admin/archive-employees')
    .then(
      async (response) => Either.Right(response.data.employees.map((rawEmployee) => {
        const clinicId = rawEmployee.clinic.id;
        const clinicName = rawEmployee.clinic.name;
        const employeeName = rawEmployee.name;
        const employeeId = rawEmployee.id;
        return new ArchiveEmployee(
          employeeId,
          employeeName,
          new ArchiveEmployeeClinic(
            clinicId,
            clinicName,
          ),
        );
      })),
      async (error) => {
        if (error.isAxiosError && error.response.status === 500) {
          const left = new LeftResponse([], 'Произошла ошибка на сервере');
          return Either.left(left);
        }
        if (error.isAxiosError) {
          const { causes, title } = error.response.data;
          const leftResponse = new LeftResponse(causes, title);
          return Either.left(leftResponse);
        } throw error;
      },
    );
}
