import {
  Either, Fail,
} from 'monet';
import LeftResponse from '../LeftResponse';

/**
 * @description Запрос на шаблон пример пациентов.
 * @param {File} file Файл.
 * @constructor
 */
export function ExamplePatientTemplateRequest(file) {
  this.toQuery = () => {
    const formData = new FormData();
    formData.append('template', file);
    return formData;
  };
}

/**
 * @description Запрос на шаблон пример прайса.
 * @param {File} file Файл.
 * @constructor
 */
export function ExamplePriceTemplateRequest(file) {
  this.toQuery = () => {
    const formData = new FormData();
    formData.append('template', file);
    return formData;
  };
}

/**
 * @description Шлюз шаблонов примеров.
 * @param {Axios} axios HTTP клиент.
 * @constructor
 */
export default function TemplateExampleGateway(axios) {
  /**
   * @description Получить пример шаблона для импорта пациентов
   *
   * @returns {Promise<Success|Fail>}
   */
  this.getTemplatePatientsExample = () => axios
    .get('/api/admin/examples-templates/patients', { responseType: 'blob' })
    .then(
      async (response) => Either.right(response.data),
      async (error) => {
        const getResponse = async () => {
          const json = await (error.response.data || '').text();
          const data = JSON.parse(json);
          return data;
        };
        if (error.isAxiosError && error.response.status === 400) {
          const response = await getResponse();
          const { title } = response;
          return Fail({ title });
        }
        if (error.isAxiosError && error.response.status === 404) {
          const response = await getResponse();
          const { title } = response;
          return Fail({ title });
        }
        throw error;
      },
    );
  /**
   * @description Загрузить шаблон пример для импорта пациента
   *
   * @param {ExamplePatientTemplateRequest} request
   * @returns {Promise<Either>}
   */
  this.uploadTemplatePatientsExample = (request) => axios
    .post('/api/admin/examples-templates/patients', request.toQuery())
    .then(
      async (response) => Either.right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 500) {
          const left = new LeftResponse([], 'Произошла ошибка на сервере');
          return Either.left(left);
        }
        if (error.isAxiosError && error.response.status === 400) {
          const { causes, title } = error.response.data;
          const leftResponse = new LeftResponse(causes, title);
          return Either.left(leftResponse);
        } throw error;
      },
    );
  /**
   * @description Получить пример шаблона для импорта прайса
   *
   * @returns {Promise<Success|Fail>}
   */
  this.getTemplatePriceExample = () => axios
    .get('/api/admin/examples-templates/price', { responseType: 'blob' })
    .then(
      async (response) => Either.right(response.data),
      async (error) => {
        const getResponse = async () => {
          const json = await (error.response.data || '').text();
          const data = JSON.parse(json);
          return data;
        };
        if (error.isAxiosError && error.response.status === 400) {
          const response = await getResponse();
          const { title } = response;
          return Fail({ title });
        }
        if (error.isAxiosError && error.response.status === 404) {
          const response = await getResponse();
          const { title } = response;
          return Fail({ title });
        }
        throw error;
      },
    );
  /**
   * @description Загрузить шаблон пример для импорта пациента
   *
   * @param {ExamplePriceTemplateRequest} request
   * @returns {Promise<Either>}
   */
  this.uploadTemplatePriceExample = (request) => axios
    .post('/api/admin/examples-templates/price', request.toQuery())
    .then(
      async (response) => Either.right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 500) {
          const left = new LeftResponse([], 'Произошла ошибка на сервере');
          return Either.left(left);
        }
        if (error.isAxiosError && error.response.status === 400) {
          const { causes, title } = error.response.data;
          const leftResponse = new LeftResponse(causes, title);
          return Either.left(leftResponse);
        } throw error;
      },
    );
}
