import {
  Maybe,
} from 'monet';

/**
 * @description Шлюз демо клиник.
 * @param {Axios} axios HTTP клиент.
 * @constructor
 */
export default function DemoClinicsGateway(axios) {
  /**
   * @description Получить таблицу демо клиник
   *
   * @param page
   * @param match
   * @return {Promise<Maybe>}
   */
  this.getDemoClinicsTable = (page, match = '') => axios
    .get(
      '/api/admin/clinics-demo',
      {
        params: {
          match,
          page,
        },
      },
    )
    .then((response) => Maybe.fromEmpty(response.data));
}
